@import '../../styles/base/variables.scss';

.custom-404 {
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: $septenary;

    h1 {
      font-size: 72px;
      color: $light-red;
      text-align: center;
    }

    h2 {
      font-size: 40px;
      text-align: center;
    }

    a {
      border-radius: 8px;
    }
  }
}